const pixels3DToImage = (pixels) => {
  const height = pixels.length;
  const width = pixels[0].length;
  const data = new Uint8ClampedArray(height * width * 4);
  for (let j = 0; j < height; j++) {
    for (let i = 0; i < width; i++) {
      const pos = j * width + i;
      data[pos*4 + 0] = pixels[j][i][0];
      data[pos*4 + 1] = pixels[j][i][1];
      data[pos*4 + 2] = pixels[j][i][2];
      data[pos*4 + 3] = 255; 
    }
  }
  const imageData = new ImageData(data, width, height);
  const canvas = document.createElement("canvas");
  canvas.height = height;
  canvas.width = width;

  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.putImageData(imageData, 0, 0);
  return canvas.toDataURL("image/png");
}

export {
  pixels3DToImage
}
