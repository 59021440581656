import React, {useEffect, useMemo, useCallback, useState} from 'react';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {useViewer} from '../contexts/viewer-context';
import {useProject} from '../contexts/project-context';

const Scanning = () => {
  const {project} = useProject();
  const [showingSelection, setShowingSelection] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const {viewerStatus, targetIndex, isARSupported, handleChangeTargetIndex, handleShowAnchorTarget} = useViewer();

  const onSwiper = useCallback((swiper) => {
    handleChangeTargetIndex(swiper.realIndex);
  }, [handleChangeTargetIndex]);

  const onClick = useCallback(() => {
    if (isARSupported) return;
    handleShowAnchorTarget(targetIndex);
  }, [handleShowAnchorTarget, targetIndex, isARSupported]);

  const targetImages = useMemo(() => {
    if (!project) return [];
    return project.targetImages;
  }, [project]);

  const showTarget = useMemo(() => {
    if (project && project.meta && project.meta.projectSettings && project.meta.projectSettings.detectionMode === 'multiple') return false;
    return true;
  }, [project]);

  const showSelection = useCallback((e) => {
    e.stopPropagation();
    setShowingSelection(true);
  }, [setShowingSelection]);

  const hideSelection = useCallback(() => {
    setShowingSelection(false);
  }, [setShowingSelection]);

  const selectTargetImage = useCallback((targetIndex) => {
    swiper.slideTo(targetIndex+1); // swiper index from 1
    hideSelection();
  }, [swiper, hideSelection]);

  useEffect(() => {
    const handler = () => {hideSelection()};
    document.body.addEventListener('click', handler); 
    return () => {
      document.body.removeEventListener('click', handler); 
    }

  }, [hideSelection]);

  if (viewerStatus === '') return null;

  return (
    <div className={classnames({hide: viewerStatus === 'showing', unseen: viewerStatus !== 'detecting'}, "overlay scanning")}>
      <div className="inner" onClick={onClick}>
        {showTarget && 
	  <Swiper onSwiper={setSwiper} loop={true} spaceBetween={0} onSlideChange={onSwiper} slidesPerView={1} className="inner-swiper">
	    {project && project.targetImages.map((targetImage, index) => (
	      <SwiperSlide key={index}>
		<img alt="target" src={targetImage.src} />
	      </SwiperSlide>
	    ))}
	  </Swiper>
	}

    	{isARSupported &&
	  <div className="scanline"></div>
	}
    	{!isARSupported &&
	  <div className="launch-guide">
	    <div className="text">Click to Launch effect</div>
	    <FontAwesomeIcon icon="hand-pointer"/>
	  </div>
	}
      </div>

      <div className={classnames({show: showingSelection}, "selection")} onClick={hideSelection}>
        <div className="items">
	  {targetImages.map((targetImage, index) => (
	    <div key={index} className="item">
	      <img alt="target" src={targetImage.src} onClick={() => selectTargetImage(index)}/>
	    </div>
	  ))}
	</div>
      </div>

      {showTarget && !showingSelection && 
	<div className="dropdown" onClick={showSelection}>
	  {project && project.targetImages[targetIndex] &&    
	    <img alt="target" src={project.targetImages[targetIndex].src} onClick={hideSelection}/>
	  }
	</div>
      }
    </div>
  )
}

export default Scanning;
