import {useProject} from '../contexts/project-context';

const PreviewOverlay = () => {
  const {project} = useProject();

  if (!project || !project.isPreview) return null;

  return (
    <div className="preview-overlay overlay">
      <div>
    	This AR effect was designed with pictarize.com - AR studio. Create your effect now for free. 
    	<a href="https://pictarize.com" alt="pictarize" target="_blank" rel="noreferrer">Start Now</a>
      </div>
    </div>
  )
}

export default PreviewOverlay;
