import React, {useState, useCallback} from 'react';
import {useViewer} from '../contexts/viewer-context';

const APP_API_URL = process.env.REACT_APP_API_URL;

const CapturePreview = () => {
  const {capturedPhoto, setCapturedPhoto} = useViewer();
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    setCapturedPhoto(null);
  }, [setCapturedPhoto]);

  const handleDownload = useCallback(async () => {
    const link = document.createElement('a');
    link.download = 'photo.png';
    link.href = capturedPhoto;
    link.click();
  }, [capturedPhoto]);

  const handleShare = useCallback(async () => {
    setLoading(true);

    const blob = await (await fetch(capturedPhoto)).blob();

    const getUploadPath = APP_API_URL + "/serve/get-share-upload";
    const response = await fetch(getUploadPath, {
      method: "POST",
      headers: { "Content-Type": "application/json" }
    }).catch((e) => {
      console.log("get upload error", e);
    });
    const res = await response.json();

    const uploadPath = res.url;
    const publicPath = res.url.split("?")[0];

    const file = new File([blob], 'photo.png', {type: blob.type, lastModified: new Date().getTime()});
    const uploadResponse = await fetch(uploadPath, {
      method: 'PUT',
      body: file,
      headers: {
	'x-amz-acl': 'public-read',
      },
    });

    if (!uploadResponse.ok) {
      setLoading(false);
      return;
    }
    
    const shareData = {
      url: publicPath,
    }
    if (!navigator.canShare || !navigator.canShare(shareData)) {
      console.log("share not availble");
      setLoading(false);
      return;
    }
    navigator.share(shareData)
    setLoading(false);
  }, [capturedPhoto, setLoading]);

  if (!capturedPhoto) return null;

  return (
    <div className="capture-preview">
      <div className="image-container">
	<img alt="capture" className="preview-photo" src={capturedPhoto} />
	<button className="close-button" onClick={handleClose}>X</button>
      </div>
      <div className="preview-control">
    	<button className="download-button" onClick={handleDownload} disabled={loading}>Download</button>
    	<button className="share-button" onClick={handleShare} disabled={loading}>Share</button>
      </div>

      {loading && 
	<div className="overlay loading">
	  <div className="loader"/>
	</div>
      }
    </div>
  )
}

export default CapturePreview;
