import React, {useEffect, useState} from 'react';
import Viewer from './components/Viewer';
import PreviewOverlay from './components/PreviewOverlay';
import PoweredByOverlay from './components/PoweredByOverlay';
import Loading from './components/Loading';
import {useProject} from './contexts/project-context';
import {ViewerProvider} from './contexts/viewer-context';

const isDevelopment = process.env.NODE_ENV === 'development';

const loadMindLibrary = (project, callback) => {
  const existingScript = document.getElementById('mind-ar-lib');

  let mindVersion = project.meta && project.meta.mindVersion;
  if (!mindVersion) mindVersion = 1;

  if (!existingScript) {
    const script = document.createElement('script');
    const url = process.env.PUBLIC_URL + "/vendor/mindar/mindar.prod.v" + mindVersion + ".js";
    //const url = process.env.PUBLIC_URL + "/vendor/mindar/mindar.js";
    script.src = url;
    script.id = 'mind-ar-lib';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const App = () => {
  const {project, loadProject, unloadProject} = useProject();
  const [started, setStarted] = useState(!isDevelopment);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (started) {
      loadProject();
    } else {
      unloadProject();
    }
  }, [loadProject, unloadProject, started]);

  useEffect(() => {
    if (project) {
      loadMindLibrary(project, () => {
	setReady(true);
      });
    }
  }, [project, setReady]);
 
  return (
      <div className="App">
	{isDevelopment && 
	  <div className="control">
	    {!started && <button onClick={() => {setStarted(true)}}>Start</button>}
	    {started && <button onClick={() => {setStarted(false)}}>Stop</button>}
	  </div>
	}

        <PreviewOverlay />
        <PoweredByOverlay />

	{!(project && ready) && <Loading />}

    	{(project && ready) && (
	  <ViewerProvider project={project}>
	    <Viewer/>
	  </ViewerProvider>
	)}
      </div>
  );
}

export default App;
