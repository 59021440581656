import React, {useRef, useEffect} from 'react';
import Scanning from './Scanning';
import Loading from './Loading';
import CapturePreview from './CapturePreview';
import ConfirmOverlay from './ConfirmOverlay';
import {useViewer} from '../contexts/viewer-context';
import {useProject} from '../contexts/project-context';
import arOnImage from '../images/ar-on.png';
import arOffImage from '../images/ar-off.png';

const Viewer = () => {
  const containerRef = useRef(null);
  const {project} = useProject();
  const {handleInit, isARMode, viewerStatus, handleToggleMode} = useViewer();

  useEffect(() => {
    handleInit({project, container: containerRef.current});
  }, [project, handleInit]);

  return (
    <div className="viewer-container" ref={containerRef}>
      <Scanning/>
      <CapturePreview />

      {viewerStatus === 'launching' &&
	<Loading/>
      }

      <ConfirmOverlay/>

      {viewerStatus === 'showing' &&
	<div className="anchor-control">
	  {isARMode && <img src={arOnImage} alt="ar-mode" onClick={handleToggleMode}/>}
	  {!isARMode && <img src={arOffImage} alt="ar-mode" onClick={handleToggleMode}/>}
	</div>
      }
    </div>
  )
}

export default Viewer;
