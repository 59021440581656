import JSZip from "jszip";
const FileType = require('file-type/browser');

const loadProject = async (projectPath) => {
  const response = await fetch(projectPath);
  const blob = await response.blob();
  const zip = new JSZip();
  await zip.loadAsync(blob);

  const mindBuffer = await zip.file('targets.mind').async('arrayBuffer');
  const meta = JSON.parse(await zip.file('meta.json').async('string'));
  const targets = JSON.parse(await zip.file('targets.json').async('string'));

  for (let i = 0; i < targets.length; i++) {
    const target = targets[i];
    const imgBlob = await zip.file('images/image' + i).async('blob');
    const imgType = await FileType.fromBlob(imgBlob);
    target.imageTarget.assetBlob = new Blob([imgBlob], {type: imgType.mime});
    
    for (const content of target.contents) {
      if (content.type === 'asset') {
	const assetId = content.asset.id;
	const blob = await zip.file('assets/' + assetId).async('blob');
	const type = await FileType.fromBlob(blob);
	if (type.mime === 'audio/vnd.wave') { // ugly hack... some mp3 file detected as vnd.wave, and cannot be played. any better solution?
	  type.mime = 'audio/mpeg';
	}
	content.assetBlob = new Blob([blob], {type: type.mime}); 
      }
    }
  }

  return {targets, meta, mindBuffer};
}

export {
  loadProject
}
