import {useProject} from '../contexts/project-context';
import poweredByImage from '../images/powered-by.png';
import classnames from 'classnames';

const PoweredByOverlay = () => {
  const {project} = useProject();

  if (!project || !project.isPreview) return null;

  return (
    <div className={classnames({hasPreview: project && project.isPreview}, "poweredby-overlay overlay")}>
      <img src={poweredByImage} alt="Pictarize Studio" />
    </div>
  )
}

export default PoweredByOverlay;
