import {THREE} from 'pictarize-lib';
import {pixels3DToImage} from '../libs/image-utils';
//import Controller from './MockController';

//const MINDAR = window.MINDAR;
//const Controller = MINDAR.Controller;

const EDITOR_SCALE = 1000;

class ARContainer {
  constructor({container, player, onDetected, onUndetected, onCapturedPhoto, regionCaptureEnabled}) {
    this.container = container;
    this.player = player;
    this.playerContainer = player.container;
    this.onDetected = onDetected;
    this.onUndetected = onUndetected;
    this.onCapturedPhoto = onCapturedPhoto;
    this.regionCaptureEnabled = regionCaptureEnabled;
    this.input = null;
    this.inputWidth = null;
    this.inputHeight = null;
    this.controller = null;
    this.anchorPostMatrix = null;
    this.detected = false;
    this.isARSupported = false;
    container.appendChild(this.playerContainer);
  }

  async initUI(mindBuffer) {
    let good = false;

    if (this.input) {
      this._stopVideo();
    }
    if (this.controller) {
      this.controller.stopProcessVideo();
    }

    // init video
    try {
      //throw "";
      const {input, inputWidth, inputHeight} = await this._startVideo();
      this.input = input;
      this.inputWidth = inputWidth;
      this.inputHeight = inputHeight;

      const controller = new window.MINDAR.Controller({inputWidth: this.inputWidth, inputHeight: this.inputHeight, onUpdate: this.onControllerUpdate.bind(this)});
      if (this.regionCaptureEnabled) {
	controller.shouldCaptureRegion = true;
      }
      const {dimensions} = controller.addImageTargetsFromBuffer(mindBuffer);

      const anchorPostMatrix = [];
      for (let i = 0; i < dimensions.length; i++) {
	const [markerWidth, markerHeight] = dimensions[i];
	const position = new THREE.Vector3();
	const quaternion = new THREE.Quaternion();
	quaternion.setFromEuler(new THREE.Euler(Math.PI/2, 0, 0));
	const scale = new THREE.Vector3();
	position.x = markerWidth / 2;
	position.y = markerWidth / 2 + (markerHeight - markerWidth) / 2;
	scale.x = markerWidth / EDITOR_SCALE;
	scale.y = markerWidth / EDITOR_SCALE;
	scale.z = markerWidth / EDITOR_SCALE;
	const postMatrix = new THREE.Matrix4();
	postMatrix.compose(position, quaternion, scale);
	anchorPostMatrix.push(postMatrix);
      }
      this.anchorPostMatrix = anchorPostMatrix;
      this.controller = controller;

      await controller.dummyRun(this.input);
      this.isARSupported = true;
      good = true;
    } catch (e) {
      console.log("ar failed", e);
    }

    if (good) {
      // ar camera
      const proj = this.controller.getProjectionMatrix();
      const fov = 2 * Math.atan(1/proj[5]) * 180 / Math.PI; // vertical fov
      const near = proj[14] / (proj[10] - 1.0);
      const far = proj[14] / (proj[10] + 1.0);
      const newAspect = this.inputWidth / this.inputHeight;

      const camera = new THREE.PerspectiveCamera();
      camera.fov = fov;
      camera.aspect = newAspect;
      camera.near = near;
      camera.far = far;
      camera.updateProjectionMatrix();
      this.camera = camera;
    }

    if (this.input) {
      this.input.style.position = "absolute"; // not sure why needed in iOS 16.
      this.container.appendChild(this.input);
    }

    this.selectTarget(0);

    this.resizeUI();

    return good;
  }

  async captureScreen() {
    console.log("ar container capture screen...");
    if (!this.input) {
      console.log("input not ready");
      return;
    }

    const playerRenderer = await this.player.requestRenderer();

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = this.container.offsetWidth;
    canvas.height = this.container.offsetHeight;

    const sx = this.inputWidth * (-1 * parseInt(this.input.style.left) / parseInt(this.input.style.width)); 
    const sy = this.inputHeight * (-1 * parseInt(this.input.style.top) / parseInt(this.input.style.height));
    const sw = this.inputWidth - sx * 2;
    const sh = this.inputHeight - sy * 2;

    context.drawImage(this.input, sx, sy, sw, sh, 0, 0, canvas.width, canvas.height);
    context.drawImage(playerRenderer.domElement, 0, 0, canvas.width, canvas.height);

    const data = canvas.toDataURL('image/png');
    this.onCapturedPhoto(data);
  }

  startAnchorAndShowTarget(targetIndex) {
    this._detected(targetIndex);
  }

  startAnchor() {
    this.player.switchToAnchor();

    if (this.isARSupported) {
      this.controller.stopProcessVideo();
    }
  }

  startAR() {
    this._undetected();

    if (this.isARSupported) {
      this.player.switchToAR(this.camera);
      this.controller.processVideo(this.input);
    }
  }

  selectTarget(targetIndex) {
    if (this.isARSupported) {
      this.controller.interestedTargetIndex = targetIndex;
    }
  }

  stop() {
    this._undetected();
  }

  _undetected() {
    this.detected = false;
    this.player.stopTarget();
    this.onUndetected();
  }
  _detected(targetIndex) {
    this.detected = true;

    let regionCapturedImage = null;
    if (this.regionCaptureEnabled) {
      const pixels = this.controller.capturedRegion;
      regionCapturedImage = pixels3DToImage(pixels);
    }
    this.player.startTarget(targetIndex, regionCapturedImage);
    this.onDetected(targetIndex);
  }

  onControllerUpdate({type, targetIndex, worldMatrix}) {
    if (type !== 'updateMatrix') return;

    if (!worldMatrix) {
      this._undetected();
      return;
    }

    // wait a few frames to make it more stable before appearing
    if (!this.detected) {
      this._detected(targetIndex);
    }

    const anchor = this.player.selectedTarget.subScene;
    const cssAnchor = this.player.selectedTarget.cssSubscene;
    const postMatrix = this.anchorPostMatrix[targetIndex];

    const m = new THREE.Matrix4();
    m.elements = worldMatrix;
    m.multiply(postMatrix);
    anchor.matrix = m;
    cssAnchor.matrix = m;
  }

  resizeUI() {
    let {container, input, inputWidth, inputHeight} = this;

    if (!input) {
      inputWidth = container.clientWidth;
      inputHeight = container.clientHeight;
    }

    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    const inputRatio = inputWidth / inputHeight;
    const containerRatio = containerWidth / containerHeight; 

    let wrapperWidth, wrapperHeight;
    if (inputRatio > containerRatio) {
      wrapperHeight = containerHeight;
      wrapperWidth = containerHeight * inputRatio;
    } else {
      wrapperWidth = containerWidth;
      wrapperHeight = containerWidth / inputRatio;
    }

    const wrapperLeftCSS = (-(wrapperWidth - containerWidth) / 2) + "px";
    const wrapperTopCSS = (-(wrapperHeight - containerHeight) / 2) + "px";
    const wrapperWidthCSS = wrapperWidth + "px";
    const wrapperHeightCSS = wrapperHeight + "px";

    if (input) {
      input.style.position = 'absolute';
      input.style.left = wrapperLeftCSS;
      input.style.top = wrapperTopCSS;
      input.style.width = wrapperWidthCSS;
      input.style.height = wrapperHeightCSS;
      input.style.zIndex = 1;
    }

    //const playerContainer = document.createElement("div");
    const playerContainer = this.playerContainer;
    playerContainer.style.position = 'absolute';
    playerContainer.style.left = wrapperLeftCSS;
    playerContainer.style.top = wrapperTopCSS;
    playerContainer.style.width = wrapperWidthCSS;
    playerContainer.style.height = wrapperHeightCSS;
    playerContainer.style.zIndex = 2;
    playerContainer.style.background = "transparent";

    this.player.resizeUI();
  }

  destroy() {
    if (this.isARSupported) {
      this.controller.stopProcessVideo();
    }
    this.player.dispose();
    this._stopVideo();
  }

  _fakeStartVideo() {
    return new Promise(async (resolve, reject) => {
      const img = document.createElement('img');
      img.onload = () => {
	resolve({input: img, inputWidth: img.width, inputHeight: img.height});
      };
      img.src = 'https://media.geeksforgeeks.org/wp-content/uploads/20190529122828/bs21.png';
    });
  }

  _startVideo() {
    //return this._fakeStartVideo();

    return new Promise(async (resolve, reject) => {
      const video = document.createElement('video');
      video.setAttribute('autoplay', '');
      video.setAttribute('muted', '');
      video.setAttribute('playsinline', '');

      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
	console.log("missing navigator.mediaDevices.getUserMedia");
	reject();
	return;
      }

      navigator.mediaDevices.getUserMedia({audio: false, video: {
	facingMode: 'environment',
      }}).then((stream) => {
	video.addEventListener( 'loadedmetadata', () => {
	  resolve({input: video, inputWidth: video.videoWidth, inputHeight: video.videoHeight});
	});
	video.srcObject = stream;
      }).catch((err) => {
	console.log("getUserMedia error", err);
	reject();
      });

    });
  }

  _stopVideo() {
    const video = this.input;
    if (video && video.srcObject) {
      const tracks = video.srcObject.getTracks();
      tracks.forEach(function(track) {
	track.stop();
      });
      video.remove();
    }
  }
}

export default ARContainer;
