import React, {useState, useCallback, useMemo, useContext} from 'react';
import {fileLoader} from 'pictarize-lib';
import {loadProject as doLoadProject} from '../libs/project';
const {loadImage} = fileLoader;

const STORAGE_ROOT = process.env.REACT_APP_STORAGE_ROOT;
const APP_API_URL = process.env.REACT_APP_API_URL;

const ProjectContext = React.createContext();

const ProjectProvider = ({children}) => {
  const [project, setProject] = useState(null);

  const unloadProject = useCallback(async () => {
    setProject(null);
  }, [setProject]);

  const loadProject = useCallback(async () => {
    let isPreview = false;
    let projectPath = null;
    if (process.env.REACT_APP_SELFHOST) {
      projectPath = process.env.PUBLIC_URL + "/build.zip";
    } else {

      const urlParams = new URLSearchParams(window.location.search);
      let buildId = urlParams.get("buildId");
      let projectId = urlParams.get("projectId");
      isPreview = true;

      if (!buildId) {
	isPreview = false;
	const subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
	let projectKey;
	if (subdomain) {
	  projectKey = subdomain;
	} else {
	  projectKey = window.location.pathname.substring(1);
	}
	if (!projectKey) return;

	try {
	  const validatePath = APP_API_URL + "/serve/valid-subscription";
	  const response = await fetch(validatePath, {
	    method: "POST",
	    headers: { "Content-Type": "application/json" },
	    body: JSON.stringify({projectKey})
	  });
	  const responseJSON = await response.json();

	  if (!responseJSON.valid) {
	    isPreview = true;
	  }

	} catch (e) {
	  console.log("valid subscription error", e);
	}

	try {
	  const projectMetaPath = STORAGE_ROOT + "/releases/" + projectKey + ".json";
	  const response = await fetch(projectMetaPath, {cache: "no-store"});
	  const responseJSON = await response.json();
	  buildId = responseJSON.buildId;
	  projectId = responseJSON.projectId;
	} catch (e) {
	  console.log("e", e);
	  return;
	}
      }

      if (buildId && projectId) {
	if (isPreview) {
	  const analyticPath = APP_API_URL + "/view/preview";
	  fetch(analyticPath, {
	    method: "POST",
	    headers: { "Content-Type": "application/json" },
	    body: JSON.stringify({projectId, buildId})
	  }).catch((e) => {
	    console.log("analytic error", e);
	  });
	} else {
	  const analyticPath = APP_API_URL + "/view/release";
	  fetch(analyticPath, {
	    method: "POST",
	    headers: { "Content-Type": "application/json" },
	    body: JSON.stringify({projectId})
	  }).catch((e) => {
	    console.log("analytic error", e);
	  });
	}
      }

      projectPath = STORAGE_ROOT + "/projects/" + projectId + "/" + buildId;
    }

    const targetImages = []
    const {targets, meta, mindBuffer} = await doLoadProject(projectPath); 
    for (let i = 0; i < targets.length; i++) {
      const targetImage = await loadImage(targets[i].imageTarget.assetBlob);
      targetImages.push(targetImage);
    }

    setProject({
      isPreview,
      targetImages,
      targets,
      mindBuffer,
      meta
    });
  }, [setProject]);

  const value = useMemo(() => {
    return {
      project,
      loadProject,
      unloadProject
    }
  }, [project, loadProject, unloadProject]);

  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
}

const useProject = () => useContext(ProjectContext);

export {
  ProjectProvider,
  useProject
}
