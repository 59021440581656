const disposeObject = (object) => {
  // dispose geometries and materials
  object.traverse(o => {
    if (o.geometry) {
      o.geometry.dispose()
    }

    if (o.material) {
      if (o.material.length) {
	for (let i = 0; i < o.material.length; ++i) {
	  if (o.material[i].map) {
	    o.material[i].map.dispose();
	  }
	  o.material[i].dispose()
	}
      }
      else {
	if (o.material.map) {
	  o.material.map.dispose();
	}
	o.material.dispose()
      }
    }
  })          
}

export default {
  disposeObject,
}
