import {useViewer} from '../contexts/viewer-context';

const ConfirmOverlay = () => {
  const {viewerStatus, handleConfirm} = useViewer();

  if (viewerStatus !== 'inited') return null;

  return (
    <div className="pre-start overlay">
      <div className="inner">
	<div>please allow camera access for AR effects</div> 
	<button onClick={handleConfirm}>Continue</button>
      </div>
    </div>
  )
}

export default ConfirmOverlay;
