import React, {useState, useRef, useCallback, useEffect, useContext} from 'react';
import {Player} from 'pictarize-lib';
import ARContainer from '../libs/ARContainer';

const ViewerContext = React.createContext({});

const ViewerProvider = ({children, project}) => {
  const arContainerRef = useRef(null);
  const playerRef = useRef(null);

  const [viewerStatus, setViewerStatus] = useState(''); // inited, confirmed, launching, detecting, showing
  const [targetIndex, setTargetIndex] = useState(-1);
  const [isARMode, setIsARMode] = useState(false);
  const [isARSupported, setIsARSupported] = useState(false);
  const [capturedPhoto, setCapturedPhoto] = useState(null);

  const startProcess = useCallback(async (arMode) => {
    const arContainer = arContainerRef.current;
    setIsARMode(arMode);
    if (arMode) {
      arContainer.startAR();
    } else {
      arContainer.startAnchor();
    }
  }, [setIsARMode]);

  const launchContainer = useCallback(async () => {
    //setIsARSupported(true);
    //setViewerStatus('detecting');
    //return;

    setViewerStatus('launching');
    const arContainer = arContainerRef.current;
    const isARSupported = await arContainer.initUI(project.mindBuffer);

    setIsARSupported(isARSupported);
    startProcess(isARSupported);

    // detech all
    if (project && project.meta && project.meta.projectSettings && project.meta.projectSettings.detectionMode === 'multiple') {
      arContainer.selectTarget(-1);
    }

    setViewerStatus('detecting');
  }, [startProcess, project, setViewerStatus, setIsARSupported]);

  const resizeContainer = useCallback(async () => {
    if (['', 'inited', 'confirmed', 'launching'].includes(viewerStatus)) return;
    const arContainer = arContainerRef.current;

    arContainer.stop();
    await launchContainer();
    //await arContainer.initUI();
  }, [viewerStatus, launchContainer]);

  const onCapturedPhoto = useCallback((photo) => {
    setCapturedPhoto(photo);
  }, [setCapturedPhoto]);

  const onShowTarget = useCallback((targetIndex) => {
    setViewerStatus('showing');
  }, [setViewerStatus]);

  const onHideTarget = useCallback((targetIndex) => {
    setViewerStatus('detecting');
  }, [setViewerStatus]);

  const handleShowAnchorTarget = useCallback((targetIndex) => {
    const arContainer = arContainerRef.current;
    arContainer.startAnchorAndShowTarget(targetIndex);
  }, []);

  const handleInit = useCallback(async ({container}) => {
    if (arContainerRef.current) {
      console.error("ar container inited");
      return;
    }

    const playerContainer = document.createElement("div");
    const player = new Player(playerContainer);

    let regionCaptureEnabled = false;
    if (project && project.meta && project.meta.projectSettings && project.meta.projectSettings.regionCaptureEnabled) {
      regionCaptureEnabled = true;
    }

    const arContainer = new ARContainer({
      container,
      regionCaptureEnabled,
      player,
      onDetected: (targetIndex) => {
	onShowTarget(targetIndex);
      },
      onUndetected: (targetIndex) => {
	onHideTarget();
      },
      onCapturedPhoto: (photo) => {
	onCapturedPhoto(photo);
      }
    });

    await player.init(project.targets, false, arContainer);

    //await arContainer.initTargets(project.targets);
    arContainerRef.current = arContainer;
    playerRef.current = player;

    setViewerStatus('inited');
  }, [project, setViewerStatus, onShowTarget, onHideTarget, onCapturedPhoto]);

  const handleConfirm = useCallback(async () => {
    setViewerStatus('confirmed');
    await playerRef.current.dummyTrigger();
    launchContainer();
  }, [setViewerStatus, launchContainer]);

  const handleChangeTargetIndex = useCallback((targetIndex) => {
    const arContainer = arContainerRef.current;
    arContainer.selectTarget(targetIndex);
    setTargetIndex(targetIndex);
  }, [setTargetIndex]);

  const handleToggleMode = useCallback(() => {
    const newARMode = !isARMode;
    const arContainer = arContainerRef.current;
    if (newARMode && !arContainer.isARSupported) {
      arContainer.stop();
      return;
    }
    setIsARMode(newARMode);
    startProcess(newARMode);
  }, [isARMode, setIsARMode, startProcess]);

  useEffect(() => {
    let timeoutHandler;
    const resizeListener = () => {
      if (timeoutHandler) clearTimeout(timeoutHandler);
      timeoutHandler = setTimeout(() => {
	resizeContainer();
      }, 100);
    }
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    }
  }, [resizeContainer]);

  useEffect(() => {
    return () => {
      if (arContainerRef.current) {
	arContainerRef.current.destroy();
      }
    }
  }, []);

  const value = {
    handleInit,
    handleConfirm,
    handleChangeTargetIndex,
    handleShowAnchorTarget,
    handleToggleMode,

    onShowTarget,
    onHideTarget,

    viewerStatus,
    targetIndex,
    isARMode,
    isARSupported,

    capturedPhoto,
    setCapturedPhoto,
  }

  return <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
}

const useViewer = () => useContext(ViewerContext);

export {
  ViewerProvider,
  useViewer
}
